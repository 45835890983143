export const prices = {
  club: 0,
  contrastoColletto: 0,
  contrastoPolsino: 0,
  contrastoChiusura: 0,
  iniziali: 3.5,
  mascherina: 6,
};

export const owner = {
  lang: "it",
  appName: "3D Shirt",
  appUrl: "https://midena.netlify.app/tessuto00003/",
  azienda: "Midena",
  attenzione: "Francesco Midena",
  indirizzo: "Viale Bruno Buozzi, 21",
  citta: "Roma",
  provincia: "Roma",
  cap: "00197",
  email: "francescomidena@gmail.com",
  telefono: "+39  06 808 4647",
  piva: "08257921000",
  registroDi: "Roma",
  foro: "Roma",
  gdpr: {
    titolare: "Francesco Midena",
    email: "francescomidena@gmail.com",
    data: "27 Gennaio 2021",
  },
  social: {
    facebook: "",
    twitter: "",
    whatsapp: "https://api.whatsapp.com/send?phone=393929068895",
  },
};
