export const lingua_it = {
  lang: "it",
  title: "3D SHIRT - Configuratore 3D per camicie su misura",
  description: "Configuratore 3D per camicie su misura",
  personalizza: "Personalizza",
  misure: "Misure",
  tessuto: "Tessuto",
  filtri: "Filtri",
  scegliTessuto: "Scegli il tessuto",
  cercaTessuto: "Cerca tessuto...",
  inOfferta: "In offerta",
  nonDisponibile: "Non disponibile",
  colore: "Colore",
  colori: "Colori",
  tuttiColori: "Tutti i colori",
  bianco: "Bianco",
  celeste: "Celeste",
  altriColori: "Altri colori",
  disegno: "Stoffe",
  disegni: "Stoffe",
  tuttiDisegni: "Tutte le stoffe",
  tintaUnita: "Stoffe tinte unite",
  righe: "Stoffe rigate",
  quadretti: "Stoffe a quadri",
  fantasia: "Stoffe fantasia stampate",
  materiale: "Materiale",
  materiali: "Materiali",
  tuttiMateriali: "Tutti i materiali",
  cotone: "Cotone",
  lino: "Lino",
  brand: "Brand",
  brands: "Brands",
  tuttiBrands: "Tutti i brands",
  codice: "Codice",
  ordina: "Ordina per prezzo",
  basso: "Dal prezzo più basso",
  alto: "Dal prezzo più alto",
  lato: "Vista laterale",
  front: "Vista frontale",
  retro: "Vista retro",
  piegata: "Vista piegata",
  extra: {
    tutti: "Tutti i prodotti",
    selTaglia: "Seleziona la taglia",
    desc: "Descrizione",
    correlati: "Prodotti correlati",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Uso dei Cookie",
    testo1:
      "In questa applicazione, facciamo uso di cookie (o tecnologie similari) per memorizzare informazioni nel tuo computer o dispositivo mobile al fine di migliorare la tua esperienza online. I cookie sono piccoli file di testo che ti consentono di navigare in modo efficiente, ricordare le tue preferenze, aiutarci a comprendere la performance dell'applicazione e migliorare in generale la tua esperienza di utilizzo.",
    testo2:
      "Chiudendo questo messaggio acconsenti all'utilizzo dei cookie in base alle tue (eventuali) preferenze.",
    gestione: "Gestione dei cookie",
    acconsento: "Acconsento",
  },
  sito: {
    titolo: "Configuratore 3D per camicie su misura",
    sottotitolo:
      "3D SHIRT è un configuratore 3D per camicie su misura con un'interfaccia semplice, immediata e coinvolgente, in grado di offrire una percezione realistica del prodotto personalizzato.",
    bottone1: "Prova il configuratore",
    titolo2: "Un configuratore 3D senza limiti",
    sottotitolo2:
      "3D SHIRT permette di avere un'esperienza di configurazione facile, veloce e coinvolgente.",
    configura: "Configura",
    titolo3: "Perchè utilizzare il configuratore 3D",
    sottotitolo3:
      "Numerosi sono i vantaggi, sia per l'utente finale che per l'azienda.",
    titolo4: "Qualità delle immagini",
    sottotitolo4:
      "Con l’utilizzo di potentissimi software di progettazione 3D per il fashion design, si ottengono immagini realistiche senza eguali.",
    titolo5: "Usabilità estrema",
    sottotitolo5:
      "Sfrutta il concetto di gamification, ovvero fa leva sull’interattività e sul divertimento per raggiungere l’obiettivo finale.",
    titolo6: "Unicità del prodotto",
    sottotitolo6:
      " Personalizzando il proprio prodotto, l’utente ha la sensazione di comprare qualcosa di speciale e unico, fatto apposta per lui.",
    titolo7: "Velocità di risposta",
    sottotitolo7:
      "Il configuratore sfrutta tecnologie all’avanguardia per lo sviluppo di app, che consentono di ottenere risposte ad ogni azione dell’utente in real time.",
    titolo8: "Personalizzazione",
    sottotitolo8:
      "Non c'è  limite al numero di elementi che è possibile integrare o modificare. Il configuratore è personalizzabile al 100% con il  proprio marchio e i propri colori.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "Regalando un’esperienza di navigazione coinvolgente, il configuratore aiuta a far crescere le vendite e la notorietà del Brand.",
    titolo10: "Caratteristiche del configuratore 3D SHIRT",
    sottotitolo10:
      "L'applicazione guida l'utente dalla personalizzazione del prodotto, fino all'acquisto.",
    titolo11: "Personalizzazione Camicia",
    sottotitolo11:
      "Attraverso pochi e semplici step, sarà possibile configurare la camicia in tutti i suoi aspetti, dalla scelta del tessuto e degli elementi che la compongono, fino all'inserimento delle misure.",
    titolo12: "Processo d'Acquisto",
    sottotitolo12:
      "L'applicazione è in grado di gestire tutte le fasi del processo d'acquisto, dalla gestione del carrello, e quindi prodotti e codici sconto, fino alla gestione della spedizione e del pagamento.",
    titolo13: "Creazione Account",
    sottotitolo13:
      "L'applicazione prevede la possibilità di creare un profilo utente personale che permetterà all'utente stesso di memorizzare le proprie misure e i propri acquisti.",
    newshop:
      "Da oggi 3D SHIRT permette la vendita di prodotti collegati alla tua sartoria! Non solo camicie personalizzate ma anche camicie pronte, cravatte o quello che vuoi!",
  },
  contact: {
    titolo: "Inviaci un messaggio",
    sottotitolo:
      "Contattaci per richiedere un preventivo o semplicemente per domande o suggerimenti. Ti risponderemo nel più breve tempo possibile.",
    nome: "Il tuo nome",
    telefono: "Il tuo telefono",
    email: "La tua email",
    messaggio: "Lascia un messaggio",
    invia: "Invia il messaggio",
  },
  header: {
    account: "Account",
    modificapassword: "Modifica Password",
    logout: "Logout",
    wishlist: "Wishlist",
    shopping: "Carrello",
    loggedOut: "Ora non sei più loggato.",
    loggedOutError: "Si è verificato un errore:",
    cambiaPasswordSuccess:
      "Ti è stata inviata una mail contenente un link unico per resettare la password.",
    cambiaPasswordError: "Si è verificato il seguente errore:",
  },
  coloriTip: {
    grigio: "Grigio",
    blu: "Blu",
    nero: "Nero",
    marrone: "Marrone",
    verde: "Verde",
    bianco: "Bianco",
    viola: "Viola",
    arancione: "Arancione",
    beige: "Beige",
    giallo: "Giallo",
    rosa: "Rosa",
    rosso: "Rosso",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Gestione dei Cookie",
    privacy: "Privacy Policy",
    termini: "Termini e Condizioni",
    recesso: "Diritto di Recesso",
    contattaci: "Contattaci",
    partita: "P.IVA",
  },
  dettaglio: {
    descrizione: "Descrizione tessuto",
    comeMisure: "Come prendere le misure",
    spedizione: "Spedizione e reso",
  },
  navigazione: {
    lang: "it",
    tessuto: "Tessuto",
    scegliTessuto: "Scegli il tessuto",
    inOfferta: "In offerta",
    nonDisponibile: "Non disponibile",
    personalizza: "Personalizza",
    personalizzazione: "Personalizzazione",
    polsinoGemelli: "Polsino Gemelli",
    contrastoColletto: "Applica al Colletto",
    contrastoPolsino: "Applica ai Polsini",
    contrastoChiusura: "Applica alla Chiusura",
    chiusura: "Chiusura",
    colletto: "Colletto",
    polsino: "Polsino",
    taschino: "Taschino",
    pinces: "Pences",
    asole: "Asole",
    bottoni: "Bottoni",
    fili: "Fili",
    contrasto: "Contrasto",
    loading: "Caricamento...",
    mascherina: "Mascherina",
    maniche: "Maniche",
    durezzaTitolo: "Durezza del colletto",
    durezza1: "Rigido",
    durezza2: "Normale",
    durezza3: "Morbido",
    durezza4: "Morbidissimo",
    velaTitolo: "Vela",
    vela1: "Vela 7",
    vela2: "Vela 7.5",
    vela3: "Vela 8",
    vela4: "Vela 8.5",
    vela5: "Vela 9",
    vela6: "Collo spaziato",
    iniziali: {
      iniziali: "Iniziali",
      inserisciIniziali: "Inserisci le iniziali",
      tueIniziali: "Le tue iniziali...",
      stile: "Scegli lo stile",
      colore: "Scegli il colore",
      bianco: "Bianco",
      blu: "Blu",
      celeste: "Celeste",
      grigio: "Grigio",
      nero: "Nero",
      rosso: "Rosso",
      verde: "Verde",
      arancione: "Arancione",
      rosa: "Rosa",
      posizione: "Scegli la posizione",
      sopra: "Sopra",
      centro: "Centro",
      sotto: "Sotto",
      aggiungiIniziali: "Aggiungi iniziali",
      stile1: "stile",
      colore1: "colore",
      posizione1: "posizione",
    },
    misure: {
      misuraNeutra: "Camicia di prova",
      misuraNeutraTitolo: "Vuoi provare la camicia prima di acquistarla?",
      misuraNeutraTesto1:
        "Richiedi una camicia neutra con le misure selezionate, al prezzo di soli €20.00.",
      misuraNeutraTesto2:
        "Il costo della camicia di prova ti verrà scontato successivamente sull'ordine definitivo.",
      metodoTitolo: "Seleziona il tuo metodo",
      metodoTaglia: "Misure da taglia standard",
      metodoCorpo: "Misura il tuo corpo",
      metodoCamicia: "Misura una camicia che ti va bene",
      metodoInvia: "Inviaci una camicia per misurarla",
      metodoModifica: "Modifica",
      metodoInformazioni: "Informazioni",
      selezionaTaglia: "Seleziona la tua taglia...",
      selezionaVestibilita: "Seleziona la tua vestibilità...",
      misure: "Misure",
      tueMisure: "Le tue misure",
      salva: "Salva le tue misure",
      errore: "Si è verificato un errore.",
      successo: "Misure salvate con successo.",
      Braccia: "Manica",
      Collo: "Collo",
      Girovita: "Giro Vita",
      Polso: "Polso",
      Spalle: "Spalle",
      Torace: "Petto",
      Lunghezza: "Lunghezza",
      ColloSpalla: "Collo Spalla",
      PettoGiro: "Petto Giro",
      PettoSpalla: "Petto Spalla",
      fianco: "Fianco",
      giroManica: "Giro Manica",
      vuoiAiuto: "Vuoi un aiuto con le misure?",
      provaSelezionare: "Prova a selezionare la tua taglia",
      successoCarica: "Misure caricate con successo.",
      seleziona: "Seleziona...",
      xs: "XS (collo 38)",
      s: "S (collo 38)",
      m: "M (collo 39/40)",
      l: "L (collo 41/42)",
      xl: "XL (collo 43/44)",
      xxl: "XXL (collo 45/46)",
      xxxl: "XXXL (collo 47/48)",
      oppure: "oppure",
      stoCaricando: "Sto caricando...",
      carica: "Carica le tue misure",
      inviaTitolo: "Inviaci la camicia da misurare",
      inviaParag1:
        "Vuoi duplicare le misure di una camicia in tuo possesso, ma non sai come misurarla correttamente?",
      inviaParag2:
        "Inviaci la tua camicia ed i nostri esperti la misureranno e conserveranno le tue misure per i prossimi acquisti. *Nota che alla tua camicia non verrà arrecato alcun danno e ti verrà rispedita nel modo migliore possibile.",
      inviaParag3: "Invia a:",
      inviaTitolo2: "Quando invii, sii sicuro di includere:",
      inviaLista1: "La camicia che vuoi farci misurare",
      inviaLista2: "Il tuo nome",
      inviaLista3: "Un recapito telefonico",
      inviaLista4: "Il tuo indirizzo email",
      inviaLista5: "L'indirizzo di spedizione",
      inviaLista6: "Eventuali istruzioni particolari",
      infoTipoMisure: "Tipo misure:",
      infoTipoMisure0: "misure da taglia",
      infoTipoMisure1: "misure da corpo",
      infoTipoMisure2: "misure da camicia",
      infoTipoMisure3: "invierà una camicia da misurare",
      infoTagliaSelezionata: "taglia selezionata:",
      infoVestibilita: "Vestibilità",
    },
  },

  barraAcquisto: {
    lang: "it",
    camicia: "Camicia",
    nonDisponibile: "Tessuto non disponibile.",
    tooltip: "Aggiungi la camicia al carrello",
    carrello: "Aggiungi al carrello",
    tessuto: "Tessuto ",
    di: " di ",
    aggiunta: "Camicia aggiunta al carrello.",
    aggiunta1: "La camicia è stata aggiunta al carrello con successo.",
    aggiunta2: "Cosa desideri fare ora?",
    continua: "Continua lo shopping",
    vai: "Carrello",
    wishlist: {
      lang: "it",
      nuovoPrezzo: "Nuovo prezzo",
      nonDisponibile: "Attenzione, tessuto non disponibile!",
      wishlist: "Wishlist",
      vuota: "La tua Wishlist è vuota!",
      Camicia: "Camicia",
      tooltip: "Aggiungi la camicia alla wishlist",
      successo: "Camicia aggiunta alla wishlist con successo.",
      rimossa: "Camicia rimossa dalla wishlist con successo.",
      Prodotto: "Prodotto",
      Prezzo: "Prezzo",
      Descrizione: "Descrizione",
      Tessuto: "Tessuto",
      Disegno: "Disegno",
      Colletto: "Colletto",
      Italiano: "Italiano",
      "Italiano Classico": "Italiano Classico",
      "Francese Classico": "Francese Classico",
      "Francese Semiaperto": "Francese Semiaperto",
      "Francese Semiaperto due bottoni": "Francese Semiaperto due bottoni",
      "Botton Down Classico": "Button Down Classico",
      "Botton Down Alto due bottoni": "Button Down Alto due bottoni",
      Diplomatico: "Diplomatico",
      Coreano: "Coreano",
      "Polsino Arrotondato": "Polsino Arrotondato",
      "Polsino Smussato": "Polsino Smussato",
      "Polsino Gemelli": "Polsino Gemelli",
      "Manica Corta": "Manica Corta",
      Chiusura: "Chiusura",
      "Faldoncino Interno non Impunturato": "Francese",
      "Faldoncino Esterno Impunturato": "Standard",
      "Finta Doppia e bottoni nascosti": "Nascosta",
      "Nessun Taschino": "Nessun Taschino",
      "Taschino Arrotondato": "Taschino Arrotondato",
      "Taschino Smussato": "Taschino Smussato",
      "Due Taschini Arrotondati": "Due Taschini Arrotondati",
      "Due Taschini Smussati": "Due Taschini Smussati",
      "Dietro Liscio": "Dietro Liscio",
      "Dietro con Doppie Pinces": "Dietro con Doppie Pinces",
      "Dietro con Doppia Piega": "Dietro con Doppia Piega",
      "Asola Bianca": "Asola Bianca",
      "Asola Blu": "Asola Blu",
      "Asola Celeste": "Asola Celeste",
      "Asola Grigia": "Asola Grigia",
      "Asola Nera": "Asola Nera",
      "Asola Rossa": "Asola Rossa",
      "Bottone Bianco": "Bottone Bianco",
      "Bottone Blu": "Bottone Blu",
      "Bottone Celeste": "Bottone Celeste",
      "Bottone Grigio": "Asola Grigia",
      "Bottone Nero": "Bottone Grigio",
      "Bottone Rosso": "Bottone Rosso",
      "Filo Bianco": "Filo Bianco",
      "Filo Blu": "Filo Blu",
      "Filo Celeste": "Filo Celeste",
      "Filo Grigio": "Filo Grigia",
      "Filo Nero": "Filo Grigio",
      "Filo Rosso": "Filo Rosso",
      Contrasto: "Contrasto",
      contrastoPolsino: "Polsino",
      contrastoColletto: "Colletto",
      contrastoChiusura: "Chiusura",
      Stile: "Stile",
      Iniziali: "Iniziali",
      iniStile: "Stile",
      iniColore: "Colore",
      iniPosizione: "Posizione",
      white: "Bianco",
      slateblue: "Blu",
      lightsteelblue: "Celeste",
      lightgray: "Grigio",
      black: "Nero",
      crimson: "Rosso",
      posizione: "Scegli la posizione",
      sopra: "Sopra",
      centro: "Centro",
      sotto: "Sotto",
      rimuovi: "Rimuovi dalla Wishlist",
      importa: "Importa nel configuratore",
      torna: "Torna al configuratore",
    },
  },
  tutorial: {
    braccia: {
      header: "Manica",
      titolo: "Misura della manica",
      descrizione:
        "Misura la manica dalla spalla sino al polso tenendo il braccio piegato a 90°.",
    },
    bracciaCamicia: {
      header: "Braccia",
      titolo: "Misura delle braccia",
      descrizione:
        "Misura la manica dalla cucitura più alta della spalla sino alla fine del polsino, tenendo la camicia ben stesa su una superficie piana.",
    },
    collo: {
      header: "Collo",
      titolo: "Misura del collo",
      descrizione:
        "Far scorrere i metro intorno al collo e misurare subito sotto il pomo di Adamo. E' importante inserire un dito tra il metro ed il collo per garantire una vestibilità confortevole.",
    },
    colloCamicia: {
      header: "Collo",
      titolo: "Misura del collo",
      descrizione:
        "Misura la distanza lineare fra il bottone del colletto e l'asola corrispondente, tenendo il colletto ben aperto e steso su una superficie piana.",
    },
    girovita: {
      header: "Girovita",
      titolo: "Misura del girovita",
      descrizione:
        "Misura il giro vita nel punto più ampio all'altezza dell'ombelico lasciando un dito tra il corpo ed il metro. Per una corretta vestibilità bisogna far aderire il metro alla vita lasciando sempre un dito di tolleranza tra il corpo ed il metro.",
    },
    girovitaCamicia: {
      header: "Girovita",
      titolo: "Misura del girovita",
      descrizione:
        "Misura il giro vita nel punto più ampio all'altezza dell'ombelico, fra il secondo e il terzo bottone dal basso, tenendo la camicia ben stesa su una superficie piana.. Se hai fianchi ampi è consigliabile prendere la misura leggermente più in basso, per comprenderli nel totale.",
    },
    polso: {
      header: "Polso",
      titolo: "Misura del polso",
      descrizione:
        "Misura la circonferenza del polso all'altezza dell'articolazione.",
    },
    polsoCamicia: {
      header: "Polso",
      titolo: "Misura del polso",
      descrizione:
        "Misura la distanza lineare fra il bottone del polsino e l'asola corrispondente, tenendo il polsino ben aperto e steso su una superficie piana.",
    },
    spalle: {
      header: "Spalle",
      titolo: "Misura delle spalle",
      descrizione: "Misurare la larghezza delle spalle nel punto più ampio.",
    },
    spalleCamicia: {
      header: "Spalle",
      titolo: "Misura delle spalle",
      descrizione: "Misurare la larghezza delle spalle nel punto più ampio.",
    },
    torace: {
      header: "Torace",
      titolo: "Misura del petto",
      descrizione:
        "Misura la circonferenza del torace all'altezza del petto. Per una corretta vestibilità bisogna far aderire il metro al torace conservando sempre un dito di tolleranza tra il corpo ed il metro in modo da potersi muovere in libertà.",
    },
    toraceCamicia: {
      header: "Torace",
      titolo: "Misura del torace",
      descrizione:
        "Misura la circonferenza del torace all'altezza del petto, fra il secondo ed il terzo bottone dall'alto. Misura la distanza lineare fra il bottone e l'opposta asola corrispondente.",
    },
    lunghezza: {
      header: "Lunghezza",
      titolo: "Misura della lunghezza",
      descrizione:
        "Inclina la testa in avanti e  misura dal centro della base posteriore del collo, fino al cavallo dei pantaloni.",
    },
    lunghezzaCamicia: {
      header: "Lunghezza",
      titolo: "Misura della lunghezza",
      descrizione:
        "Tenendo la camicia ben stesa su una superficie piana  misura dal centro della base posteriore del collo, in  corrispondenza del centro della cucitura fra camicia e colletto, fino all'orlo inferiore.",
    },
    colloSpalla: {
      header: "Collo Spalla",
      titolo: "Collo Spalla",
      descrizione:
        "Misura la distanza dall'attaccamento del collo all'estremità della spalla. È importante per determinare se si desidera una spalla precisa 'sartoriale' o più casual 'Large'.",
    },
    pettoGiro: {
      header: "Petto Giro",
      titolo: "Petto Giro",
      descrizione:
        "Misura il davanti tra il secondo e il terzo bottone fino alla cucitura della manica.",
    },
    pettoSpalla: {
      header: "Petto Spalla",
      titolo: "Petto Spalla",
      descrizione:
        "Misura dal collo fino alla cucitura della manica con la spalla.",
    },
    fianco: {
      header: "Fianco",
      titolo: "Fianco",
      descrizione: "Misura il fianco nel punto più ampio.",
    },
    giroManica: {
      header: "Giro Manica",
      titolo: "Giro Manica",
      descrizione:
        "Misura il giromanica all'altezza della spalla, lasciando un dito tra il metro e il braccio.",
    },
  },
  contatti: {
    richiesto: "Campo richiesto",
    emailInvalida: "Indirizzo email non valido",
    titolo: "Noi adoriamo dialogare con te",
    successo: "Grazie. Il tuo messaggio è stato inviato con successo.",
    errore: "Attenzione. Si è verificato un errore.",
    nome: "Nome *",
    email: "Email *",
    oggetto: "Oggetto *",
    messaggio: "Messaggio *",
    attendere: "Attendere...",
    invia: "Invia",
    testo01:
      "OGNI ORDINE HA BISOGNO DI ALMENO 20 GIORNI LAVORATIVI PRIMI DI ESSERE SPEDITO.CI SCUSIAMO PER IL DISAGIO MA LA VOSTRA CAMICIA NON SARÀ COME TUTTE LE ALTRE.",
    testo02:
      "La Sartoria Turrisi è a Ceglie Messapica, accogliente cittadina della Provincia di Brindisi, nel sud della Puglia.",
    testo03:
      "Dal 1970 tramandiamo la forte tradizione sartoriale, la nostra è una piccola azienda artigianale che opera con passione in ogni fase della lavorazione, eseguita esclusivamente all’interno della nostra sartoria da manodopera italiana altamente specializzata.",
    testo04:
      "La scelta dei tessuti e degli accessori di ottima qualità ci consentono di offrire l’eccellenza dell’autentico “Made in Italy”.",
    testo05:
      "Il nostro obiettivo è quello di soddisfare sotto ogni aspetto il nostro cliente, offrendo la nostra esperienza nel settore.",
    testo06:
      "Oggi è possibile confondere uno scultore con un sarto, perché tutti e due creano delle forme.",
    testo07: "KARL KRAUS",
    testo08: "TOCCA CON MANO LA NOSTRA SELEZIONE DI TESSUTI.",
    testo09: "ORDINA ORA IL TUO KIT GRATUITO!",
  },
  pagina404: {
    errore: "Errore 404",
    nonEsiste: "La pagina che cerchi non esiste!",
    torna: "Torna al configuratore",
  },
};
