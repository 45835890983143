export const selezionalingua = (val) => {
  return {
    type: "LINGUA",
    payload: val,
  };
};

export const setflagintera = (val) => {
  return {
    type: "FLAGINTERA",
    payload: val,
  };
};
export const setflaglegenda = (val) => {
  return {
    type: "FLAGLEGENDA",
    payload: val,
  };
};

export const selscroll = (val) => {
  return {
    type: "SCROLL",
    payload: val,
  };
};

export const selezionacustomization = (val) => {
  return {
    type: "CUSTOMIZATION",
    payload: val,
  };
};

export const setpersonalizzazione = (val) => {
  return {
    type: "PERSONALIZZAZIONE",
    payload: val,
  };
};

export const setopenmain = (val) => {
  return {
    type: "OPENMAIN",
    payload: val,
  };
};

export const setopenfiltri = (val) => {
  return {
    type: "FILTRI",
    payload: val,
  };
};

export const setfiltripersonalizza = (val) => {
  return {
    type: "FILTRIPERSONALIZZA",
    payload: val,
  };
};

export const setcolore = (val) => {
  return {
    type: "SETCOLORE",
    payload: val,
  };
};

export const setmateriale = (val) => {
  return {
    type: "SETMATERIALE",
    payload: val,
  };
};

export const setdisegno = (val) => {
  return {
    type: "SETDISEGNO",
    payload: val,
  };
};

export const setbrand = (val) => {
  return {
    type: "SETBRAND",
    payload: val,
  };
};

export const setcolletto = (val) => {
  return {
    type: "COLLETTO",
    payload: val,
  };
};

export const setdurezzacolletto = (val) => {
  return {
    type: "DUREZZACOLLETTO",
    payload: val,
  };
};

export const setvela = (val) => {
  return {
    type: "VELA",
    payload: val,
  };
};

export const setpolsino = (val) => {
  return {
    type: "POLSINO",
    payload: val,
  };
};

export const setclub = (val) => {
  return {
    type: "CLUB",
    payload: val,
  };
};

export const setchiusura = (val) => {
  return {
    type: "CHIUSURA",
    payload: val,
  };
};

export const settaschino = (val) => {
  return {
    type: "TASCHINO",
    payload: val,
  };
};

export const setcontrasto = (val) => {
  return {
    type: "CONTRASTO",
    payload: val,
  };
};

export const setcontrastocolletto = (val) => {
  return {
    type: "CONTRASTOCOLLETTO",
    payload: val,
  };
};

export const setcontrastopolsino = (val) => {
  return {
    type: "CONTRASTOPOLSINO",
    payload: val,
  };
};

export const setcontrastochiusura = (val) => {
  return {
    type: "CONTRASTOCHIUSURA",
    payload: val,
  };
};

export const setasola = (val) => {
  return {
    type: "ASOLA",
    payload: val,
  };
};

export const setbottone = (val) => {
  return {
    type: "BOTTONE",
    payload: val,
  };
};

export const setfilo = (val) => {
  return {
    type: "FILO",
    payload: val,
  };
};

export const setelastico = (val) => {
  return {
    type: "ELASTICO",
    payload: val,
  };
};

export const setmask = (val) => {
  return {
    type: "SETMASK",
    payload: val,
  };
};

export const setpince = (val) => {
  return {
    type: "PINCE",
    payload: val,
  };
};

export const setvista = (val) => {
  return {
    type: "VISTA",
    payload: val,
  };
};

export const setcameraorbit = (val) => {
  return {
    type: "CAMERAORBIT",
    payload: val,
  };
};

export const setfieldofview = (val) => {
  return {
    type: "FIELDOFVIEW",
    payload: val,
  };
};

export const attivainiziali = (val) => {
  return {
    type: "ATTIVAINIZIALI",
    payload: val,
  };
};

export const setinitesto = (val) => {
  return {
    type: "INIZIALITESTO",
    payload: val,
  };
};

export const setinistile = (val) => {
  return {
    type: "INIZIALISTILE",
    payload: val,
  };
};

export const setinicolore = (val) => {
  return {
    type: "INIZIALICOLORE",
    payload: val,
  };
};

export const setiniposizione = (val) => {
  return {
    type: "INIZIALIPOSIZIONE",
    payload: val,
  };
};

export const settipomisure = (val) => {
  return {
    type: "TIPOMISURE",
    payload: val,
  };
};

export const setneutra = (val) => {
  return {
    type: "NEUTRA",
    payload: val,
  };
};

export const presamisure = (nam, val) => {
  return {
    type: "PRESAMISURE",
    misura: nam,
    payload: val,
  };
};

export const aggiornamisure = (val) => {
  return {
    type: "AGGIORNAMISURE",
    payload: val,
  };
};

export const aggiornafit = (val) => {
  return {
    type: "AGGIORNAFIT",
    payload: val,
  };
};

export const setrotazione = (val) => {
  return {
    type: "SETROTAZIONE",
    payload: val,
  };
};
