export const lingua_es = {
  lang: "es",
  title: "3D SHIRT - Configurador 3d para camisas a medida",
  description: "Configurador 3d para camisas a medida",
  personalizza: "Personalizar",
  misure: "Medidas",
  tessuto: "Tejido",
  filtri: "Filtros",
  scegliTessuto: "Elige el tejido",
  cercaTessuto: "Buscar tejido...",
  inOfferta: "En oferta",
  nonDisponibile: "No disponible",
  colore: "Color",
  colori: "Colores",
  tuttiColori: "Todos los colores",
  bianco: "Blanco",
  celeste: "Celeste",
  altriColori: "Otros colores",
  disegno: "Tejidos",
  disegni: "Tejidos",
  tuttiDisegni: "Todos los tejidos",
  tintaUnita: "Tejidos lisos",
  righe: "Tejidos a rayas",
  quadretti: "Tejidos a cuadros",
  fantasia: "Tejidos estampados ",
  materiale: "Material",
  materiali: "Materiales",
  tuttiMateriali: "Todos los materiales",
  cotone: "Algodon",
  lino: "Lino",
  brand: "Marca",
  brands: "Marcas",
  tuttiBrands: "Todas las marcas",
  codice: "Codigo",
  ordina: "Ordinar por precio",
  basso: "De màs bajo a màs alto",
  alto: "De màs alto a màs bajo",
  lato: "Vista laterale",
  front: "Vista frontale",
  retro: "Vista retro",
  piegata: "Vista piegata",
  extra: {
    tutti: "Todos los productos",
    selTaglia: "Elige la talla",
    desc: "Descripción",
    correlati: "Productos relacionados",
  },
  bubble: {
    prima: "Clicca qui per tornare alla vista camicia.",
    seconda: "Trascina con il tasto SINISTRO del mouse per ruotare la camicia",
    terza: "Trascina con il tasto DESTRO del mouse per spostare la camicia.",
    quarta: "Muovi la ROTELLINA del mouse per lo zoom.",
    quinta: "OK, CHIUDI",
    sesta: "Trascina con un dito per ruotare la camicia.",
    settima: "Trascina con due dita per spostare la camicia.",
    ottava: "Allarga o stringi con due dita per lo zoom.",
  },
  banner: {
    titolo: "Uso de Cookies",
    testo1:
      "En esta aplicación, utilizamos cookies (o tecnologías similares) para almacenar información en tu ordenador o dispositivo móvil con el fin de mejorar tu experiencia on líne. Las cookies son pequeños archivos de texto que le permiten navegar de manera eficiente, recordar sus preferencias, ayudarnos a comprender el rendimiento de la aplicación y, en general, mejorar su experiencia de usuario.",
    testo2:
      "Al cerrar este mensaje, aceptas el uso de cookies en función de sus (posibles) preferencias.",
    gestione: "Manejo de cookies",
    acconsento: "Estoy de acuerdo",
  },
  sito: {
    titolo: "Configurador 3D para camisas a medida",
    sottotitolo:
      "3D SHIRT es un configurador 3D para camisas a medida con una interfaz simple, inmediata y atractiva, capaz de ofrecer una percepción realista del producto personalizado.",
    bottone1: "Prueba el configurador",
    titolo2: "Un configurador 3D ilimitado",
    sottotitolo2:
      "3D SHIRT le permite tener una experiencia de configuración fácil, rápida y atractiva.",
    configura: "Configurar",
    titolo3: "Por qué usar el configurador 3D",
    sottotitolo3:
      "Existen numerosas ventajas, tanto para el usuario final como para la empresa.",
    titolo4: "Calidad de imagen",
    sottotitolo4:
      "Con el uso de un potente software 3D para el diseño de moda, se obtienen imágenes realistas incomparables.",
    titolo5: "Usabilidad extrema",
    sottotitolo5:
      "Aproveche el concepto de gamificación, utilizando la interactividad y la diversión para lograr el objetivo final.",
    titolo6: "Unicidad del producto",
    sottotitolo6:
      " Al personalizar su producto, el usuario tiene la sensación de comprar algo especial y único, hecho especialmente para él.",
    titolo7: "Velocidad de respuesta",
    sottotitolo7:
      "El configurador utiliza tecnologías de vanguardia para el desarrollo de aplicaciones, que le permiten obtener respuestas a cada acción del usuario en tiempo real.",
    titolo8: "Personalización",
    sottotitolo8:
      " El configurador no tiene límites y es 100% personalizable con su propia marca y colores.",
    titolo9: " Web Marketing",
    sottotitolo9:
      "Al brindar una experiencia de navegación inmersiva, el configurador ayuda a aumentar las ventas y el conocimiento de la marca.",
    titolo10: "Características del configurador 3D Shirt",
    sottotitolo10:
      "La aplicación guía al usuario desde la personalización del producto hasta la compra.",
    titolo11: "Personalizacion de la camisa",
    sottotitolo11:
      "A través de pasos simples, será posible configurar la camisa en todos sus aspectos, desde la elección de la tela y los elementos que la componen, hasta la inserción de las medidas.",
    titolo12: "Proceso de compra",
    sottotitolo12:
      "La aplicación puede administrar todas las etapas del proceso de compra, desde la administración del carrito y, por lo tanto, los productos y los códigos de descuento, hasta la administración del envío y el pago.",
    titolo13: "Creación de cuenta",
    sottotitolo13:
      "La aplicación ofrece la posibilidad de poder crear un perfil de usuario que le permitirá almacenar sus mediciones y compras.",
    newshop:
      "¡Ahora 3D SHIRT te permite la venta de todos los productos que exhibes en tu sastrería! ¡No solo camisas personalizadas sino también camisas confeccionadas, corbatas, complementos o lo que quieras!",
  },
  contact: {
    titolo: "Envianos un mensaje",
    sottotitolo:
      "Contáctenos para solicitar una cotización o simplemente para preguntas o sugerencias relacionadas con una posible solicitud. Intentaremos responderte lo antes posible.",
    nome: "Tu nombre",
    telefono: "Su telefono",
    email: "Su correo electronico",
    messaggio: "Deja un mensaje",
    invia: "Enviar el mensaje",
  },
  header: {
    account: "Account",
    modificapassword: "Cambiar Contraseña",
    logout: "Logout",
    wishlist: "Lista de Deseos",
    shopping: "Cesta de Compra",
    loggedOut: "Ya no estás conectado.",
    loggedOutError: "Ha ocurrido un error:",
    cambiaPasswordSuccess:
      "Le enviaremos un correo electrónico con un enlace único para restablecer su contraseña.",
    cambiaPasswordError: "Ha ocurrido el siguiente error:",
  },
  coloriTip: {
    grigio: "Gris",
    blu: "Azul",
    nero: "Negro",
    marrone: "Marrón",
    verde: "Verde",
    bianco: "Blanco",
    viola: "Viola",
    arancione: "Naranja",
    beige: "Beige",
    giallo: "Amarillo",
    rosa: "Rosa",
    rosso: "Rojo",
  },
  footer: {
    account: "Account",
    login: "Login",
    logout: "Logout",
    cookie: "Cookie Policy",
    privacy: "Privacy Policy",
    termini: "Términos y Condiciones",
    recesso: "Derecho de Anular",
    contattaci: "Contáctenos",
    partita: "P.IVA",
  },
  dettaglio: {
    descrizione: "Descripción del tejido",
    comeMisure: "Cómo tomar medididas",
    spedizione: "Envío y devolución",
  },
  navigazione: {
    lang: "es",
    tessuto: "Tejido",
    scegliTessuto: "Elige el tejido",
    inOfferta: "En oferta",
    nonDisponibile: "No disponible",
    personalizza: "Personalizar",
    personalizzazione: "Personalización",
    polsinoGemelli: "Puño gemelos",
    contrastoColletto: "Aplicar al cuello.",
    contrastoPolsino: "Aplicar al puño",
    contrastoChiusura: "Aplicar à la cierre",
    chiusura: "Cierre",
    colletto: "Cuello",
    polsino: "Puño",
    taschino: "Bolsillos",
    pinces: "Pinzas",
    asole: "Ojal",
    bottoni: "Botons",
    fili: "Hilo",
    contrasto: "Contraste",
    loading: "Cargando...",
    mascherina: "Mascarilla",
    maniche: "Mangas",
    durezzaTitolo: "Dureza del cuello",
    durezza1: "Rigido",
    durezza2: "Normal",
    durezza3: "Suave",
    durezza4: "Super Suave",
    velaTitolo: "Pala",
    vela1: "Pala 7",
    vela2: "Pala 7.5",
    vela3: "Pala 8",
    vela4: "Pala 8.5",
    vela5: "Pala 9",
    vela6: "Cuello espaciado",
    iniziali: {
      iniziali: "Iniciales",
      inserisciIniziali: "Entrez vos Iniciales",
      tueIniziali: "Entrez vos Iniciales",
      stile: "Estilo Iniciales",
      colore: "Color Iniciales",
      bianco: "Blanco",
      blu: "Azul",
      celeste: "Celeste",
      grigio: "Gris",
      nero: "Negro",
      rosso: "Rojo",
      verde: "Verde",
      arancione: "Naranja",
      rosa: "Rosada",
      posizione: "Posición iniciales",
      sopra: "Arriba",
      centro: "Centro",
      sotto: "Abajo",
    },
    misure: {
      misuraNeutra: "Camisa de prueba",
      misuraNeutraTitolo: "¿Quieres probarte la camisa antes de comprarla?",
      misuraNeutraTesto1:
        "Solicite una camisa neutra en las tallas seleccionadas, a un precio de solo 20,00 €.",
      misuraNeutraTesto2:
        "El costo de la camisa de prueba se descontará más adelante en el pedido final.",
      metodoTitolo: "Selecciona tu método",
      metodoTaglia: "Mediciones de tallas estándar",
      metodoCorpo: "Mide tu cuerpo",
      metodoCamicia: "Mide una camisa que te quede bien",
      metodoInvia: "Envíanos una camisa para medirla",
      metodoModifica: "Editar",
      metodoInformazioni: "Información",
      selezionaTaglia: "Selecciona tu talla...",
      selezionaVestibilita: "Selecciona tu ajuste...",
      misure: "Medidas",
      tueMisure: "Tus medidas",
      salva: "Guardar tus medidas",
      errore: "Error al cargar",
      successo: "Medidas cargadas con éxito",
      Braccia: "Manga",
      Collo: "Cuello",
      Girovita: "Cintura",
      Polso: "Puño ",
      Spalle: "Hombros",
      Torace: "Pecho",
      Lunghezza: "Largo",
      ColloSpalla: "Collo Spalla",
      PettoGiro: "Petto Giro",
      PettoSpalla: "Petto Spalla",
      fianco: "Fianco",
      giroManica: "Giro Manica",
      dimColloSpalla: "Cuello - Hombros",
      PettoGiro: "Redondo Pecho",
      ColloSpalla: "Cuello - Tamano de Hombro",
      fianco: "Medida Lateral",
      giroManica: "Brazo",
      vuoiAiuto: "Quieres ayuda con las medidas?",
      provaSelezionare: "Seleccionar tu talla",
      successoCarica: "Medidas cargadas con éxito",
      seleziona: "Seleccionar...",
      xs: "XS (Cuello 38)",
      s: "S (Cuello 38)",
      m: "M (Cuello 39/40)",
      l: "L (Cuello 41/42)",
      xl: "XL (Cuello 43/44)",
      xxl: "XXL (Cuello 45/46)",
      xxxl: "XXXL (Cuello 47/48)",
      oppure: "o",
      stoCaricando: "Cargando...",
      carica: "Sube tus medidas",
      inviaTitolo: "Envíanos la camisa a medir",
      inviaParag1:
        "¿Desea duplicar las medidas de una camisa en su posesión, pero no sabe cómo medirla correctamente?",
      inviaParag2:
        "Envíenos su camisa y nuestros expertos la medirán y guardarán sus medidas para futuras compras. * Tenga en cuenta que no se dañará su camisa y se la enviaremos de la mejor manera posible.",
      inviaParag3: "Enviar a:",
      inviaTitolo2: "Al enviar, asegúrese de incluir:",
      inviaLista1: "La camisa que quieres que midamos",
      inviaLista2: "Tu nombre",
      inviaLista3: "Un número de teléfono",
      inviaLista4: "Su dirección de correo electrónico",
      inviaLista5: "La dirección de envío",
      inviaLista6: "Cualquier instrucción especial",
      infoTipoMisure: "Tipo de medidas:",
      infoTipoMisure0: "medidas por talla",
      infoTipoMisure1: "medidas del cuerpo",
      infoTipoMisure2: "medidas de la camisa",
      infoTipoMisure3: "Enviaré una camisa a medida",
      infoTagliaSelezionata: "Talla seleccionada:",
      infoVestibilita: "Ajuste",
      aggiungiIniziali: "Agregar iniciales",
      stile1: "estilo",
      colore1: "colore",
      posizione1: "ubicación",
    },
  },
  barraAcquisto: {
    lang: "es",
    nonDisponibile: "Tejido no disponible.",
    tooltip: "Añadir esta camisa a la Cesta de Compra",
    carrello: "Añadir a la Cesta de Compra",
    camicia: "Camisa ",
    tessuto: "Tejido ",
    di: " de",
    aggiunta: "Camisa añadida a la Cesta de Compra",
    aggiunta1: "Camisa añadida con éxito a la Cesta de Compra.",
    aggiunta2: "¿Qué quieres hacer ahora?",
    continua: "Continúa con tus compras",
    vai: "Ir a la Cesta de Compra",
    wishlist: {
      lang: "es",
      nuovoPrezzo: "Nuevo precio",
      nonDisponibile: "Atención, tejido no disponible!",
      wishlist: "Lista de Deseos",
      vuota: "Tu lista de deseos está vacía.",
      Camicia: "Camisa",
      tooltip: "Agregar la camisa a la Wishlist",
      successo: "Camiseta agregada con éxito a la Wishlist.",
      rimossa: "Camiseta eliminada con éxito de la Wishlist.",
      Prodotto: "Producto",
      Prezzo: "Precio",
      Descrizione: "Descripción",
      Tessuto: "Tela",
      Disegno: "Dibujo",
      Colletto: "Cuello",
      Italiano: "Italiano",
      "Italiano Classico": "Italiano clásico",
      "Francese Classico": "Francés clásico",
      "Francese Semiaperto": "Semi-abierto francés",
      "Francese Semiaperto due bottoni": "Semi-abierto frances dos botones",
      "Botton Down Classico": "Button Down",
      "Botton Down alto due bottoni": "Button Down alto dos botones",
      Diplomatico: "Diplomático",
      Coreano: "Coreano",
      "Polsino arrotondato": "Puño redondeado",
      "Polsino smussato ": "Puño biselado",
      "Polsino gemelli": "Puño gemelos",
      "Manica corta": "Manga corta",
      Chiusura: "Cierre",
      "Faldoncino interno non Impunturato": "francés",
      "Faldoncino Esterno Impunturato": "Estándar",
      "Finta Doppia e bottoni nascosti": "Oculto",
      "Nessun Taschino": "No bolsillo",
      "Taschino arrotondato": "Bolsillo redondeado",
      "Taschino smussato": "Bolsillo biselado",
      "Dos taschini arrotondati": "Dos bolsillos redondeados",
      "Due taschini smussati": "Dos bolsollos biselados",
      "Dietro liscio": "Detrás liso",
      "Dietro con doppie pinces": "Detrás con pinzas dobles",
      "Dietro con doppia piega": "Detrás con pinza central",
      "Asola bianca": "Ojal blanco",
      "Asola blu": "Ojal azul",
      "Asola celeste": "Ojal celeste",
      "Asola grigia": "Ojal gris",
      "Asola nera ": "Ojal negro",
      "Asola rossa": "Ojal rojo",
      "Bottone bianco": "Botones blanco",
      "Bottone blu": "Botones azul",
      "Bottone celeste": "Botones celeste",
      "Bottone grigio": "Botones gris",
      "Bottone nero": "Botones nero",
      "Bottone rosso": "Botones rojo",
      "Filo bianco": "Hilo blanco",
      "Filo blu": "Hilo azul",
      "Filo celeste": "Hilo celeste",
      "Filo grigio": "Hilo gris",
      "Filo nero": "Hilo negro",
      "Filo rosso": "Hilo rojo",
      Contrasto: "Contraste",
      contrastoPolsino: "Puño",
      contrastoColletto: "Cuello",
      contrastoChiusura: "Cierre",
      Stile: "Estilo",
      Iniziali: "Iniciales",
      iniStile: "Estilo",
      iniColore: "Color",
      iniPosizione: "Posición",
      white: "Blanco",
      slateblue: "Azul",
      lightsteelblue: "Celeste",
      lightgray: "Grey",
      black: "Negro",
      crimson: "Rojo",
      posizione: "Elegir posición",
      sopra: "Arriba",
      centro: "Centro",
      sotto: "Abajo",
      rimuovi: "Eliminar de la Wishlist",
      importa: "Importar en el configurador",
      torna: "Volver al configurador",
    },
  },
  tutorial: {
    braccia: {
      header: "Manga",
      titolo: "Manga",
      descrizione:
        "Medir la manga desde el principio del hombro hasta la muñeca doblando el brazo a 90°.",
    },
    bracciaCamicia: {
      header: "Brazos",
      titolo: "Medidas de los Brazos",
      descrizione:
        "Mida la manga desde la costura más alta del hombro hasta el extremo del puño, manteniendo la camisa plana sobre una superficie plana.",
    },
    collo: {
      header: "Cuello",
      titolo: "Medidas del cuello",
      descrizione:
        "Pase la cinta métrica alrededor del cuello y mida justo debajo de la nuez de Adán. Es importante insertar un dedo entre la cinta métrica y el cuello para asegurar un ajuste cómodo.",
    },
    colloCamicia: {
      header: "Cuello",
      titolo: "Medidas del cuello",
      descrizione:
        "Mida la distancia lineal entre el botón del collar y el ojal correspondiente, manteniendo el collar abierto y acostado sobre una superficie plana.",
    },
    girovita: {
      header: "Cintura",
      titolo: "Medida de cintura",
      descrizione:
        "Medir la circunferencia de la cintura en el punto más ancho a la altura del ombligo, dejando un dedo de tolerancia entre el cuerpo y el metro, para poder sentarse cómodamente. Es importante tomar la medida precisa, manteniendo el metro adherido al cuerpo.",
    },
    girovitaCamicia: {
      header: "Cintura",
      titolo: "Medida de cintura",
      descrizione:
        "Mida la cintura en el punto más ancho del ombligo, entre el segundo y el tercer botón desde la parte inferior, manteniendo la camisa plana sobre una superficie plana. Si tiene caderas anchas, es recomendable tomar la medida un poco más abajo. para entenderlos en total.",
    },
    polso: {
      header: "Puño",
      titolo: "Medidas del puño",
      descrizione:
        "Medir la circunferencia de la muñeca a la altura de la articulación.",
    },
    polsoCamicia: {
      header: "Puño",
      titolo: "Medidas del puño",
      descrizione:
        "Mida la distancia lineal entre el botón del puño y el ojal correspondiente, manteniendo el puño abierto y acostado sobre una superficie plana.",
    },
    spalle: {
      header: "Hombros",
      titolo: "Medidas de los hombros",
      descrizione: "Medir el ancho de los hombros en el punto más ancho.",
    },
    spalleCamicia: {
      header: "Hombros",
      titolo: "Medidas de los hombros",
      descrizione:
        "Mida los hombros en el punto más ancho, desde la costura superior del brazo izquierdo hasta la del brazo derecho, manteniendo la camisa plana sobre una superficie plana.",
    },
    torace: {
      header: "Torax",
      titolo: "Medidas del torax",
      descrizione:
        "Medir la circunferencia del torax al nivel del pecho, siempre manteniendo un dedo de tolerancia entre el cuerpo y el metro para que pueda moverse con libertad.",
    },
    toraceCamicia: {
      header: "Torax",
      titolo: "Medidas del torax",
      descrizione:
        "Mida la circunferencia del pecho entre el segundo y el tercer botón desde arriba. Mida la distancia lineal entre el botón y el ojal correspondiente opuesto.",
    },
    lunghezza: {
      header: "Largo",
      titolo: "Largo",
      descrizione:
        "Incline su cabeza hacia adelante y mida desde el centro de la base trasera del cuello hasta la entrepierna del pantalón.",
    },
    lunghezzaCamicia: {
      header: "Longitud",
      titolo: "Medidas de longitud",
      descrizione:
        "Manteniendo la camisa plana sobre una superficie plana, mida desde el centro de la base posterior del cuello, en el centro de la costura entre la camisa y el cuello, hasta el dobladillo inferior.",
    },
    colloSpalla: {
      header: "Cuello - Hombros",
      titolo: "Cuello - Hombros",
      descrizione:
        "Mida la distancia desde la unión del cuello hasta el final del hombro. Es importante determinar si desea un hombro preciso 'a la medida' o casual 'Large'.",
    },
    pettoGiro: {
      header: "Redondo Pecho",
      titolo: "Redondo Pecho",
      descrizione:
        "Mida el frente entre el segundo y el tercero botones hasta la costura de la manga.",
    },
    pettoSpalla: {
      header: "Medida pecho-hombro",
      titolo: "Medida pecho-hombro",
      descrizione:
        "Mide desde el pecho hasta la costura de la manga con el hombro.",
    },
    fianco: {
      header: "Medida de la cadera",
      titolo: "Medida de la cadera ",
      descrizione: "Medir la cadera en el punto más ancho.",
    },
    giroManica: {
      header: "Medida de la sisa",
      titolo: "Medida de la sisa",
      descrizione:
        "Mide las sisas a la altura de los hombros, dejando un dedo entre la cinta métrica y el brazo.",
    },
  },
  contatti: {
    richiesto: "Campo obligatorio",
    emailInvalida: "Dirección de correo electrónico no válida",
    titolo: "Nos encanta hablar contigo",
    successo: "Gracias. Su mensaje ha sido enviado con éxito.",
    errore: "Cuidado. Ha ocurrido un error.",
    nome: "Nombre *",
    email: "Email *",
    oggetto: "Sujeto *",
    messaggio: "Mensaje *",
    attendere: "Esperar...",
    invia: "Enviar",
    testo01:
      "CADA PEDIDO REQUIERE AL MENOS 20 DÍAS LABORABLES ANTES DEL ENVÍO. Nos disculpamos por esta molestia, pero su camisa no será como todas las demás.",
    testo02:
      "Sartoria Turrisi se encuentra en Ceglie Messapica, una ciudad acogedora en la provincia de Brindisi, en el sur de Puglia.",
    testo03:
      "Desde 1970, nuestra sólida tradición de vestimenta se ha transmitido de padres a hijos: somos una pequeña empresa artesanal que opera con pasión en cada etapa de la producción, que se lleva a cabo exclusivamente en nuestra sastrería por artesanos italianos altamente especializados.",
    testo04:
      'La elección de telas y accesorios de la más alta calidad nos permite ofrecer la excelencia del auténtico "Made in Italy".',
    testo05:
      "Nuestro objetivo es satisfacer a nuestros clientes en todos los aspectos, poniendo nuestra larga experiencia a su disposición.",
    testo06:
      "Hoy es posible confundir a un escultor con un sastre, porque ambos crean formas.",
    testo07: "KARL KRAUS",
    testo08: "Puedes apreciar la calidad de nuestros tejidos con TUS MANOS¡",
    testo09: "PIDE TU KIT GRATIS AHORA!",
  },
  pagina404: {
    errore: "Error 404",
    nonEsiste: "¡La página que buscas no existe!",
    torna: "Volver al configurador",
  },
};
